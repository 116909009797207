<template>
  <div class="settings">
    <div class="content">
      <loader v-if="!pageLoaded"></loader>
      <div v-else-if="!activateCreateRequest" style="margin-bottom: 100px;">


        <div v-if="user.status === '0'" style="text-align:center;border:1px solid #e1e1e1;background: white; height: 275px; margin: 45px 30px 0; padding: 0; display: flex;align-content: center; align-items: center;">
          <div style="text-align: center;flex:1">
            <h3>Hesabın aktif değil, bu yüzden isteğin yayınlanmıyor.</h3>
            <div class="hiddendiv"></div>
            <div class="subheading1-text">Hesabının aktif hale gelmesi için <strong>{{user.email}} </strong> adresine bir e-posta gönderdik. <br> <br>Lütfen e-posta hesabını kontrol et.</div>
            <div class="hiddendiv10"></div>
            <a target="_blank" href="/non-activated-user"  style="cursor: pointer; color: #fd4056!important;font-weight: 500!important;">Yardım</a>
            <div class="hiddendiv"></div>
            <div class="hiddendiv10"></div>
            <p style="font-weight: 400; font-size: 14px; color: #000000; text-decoration: underline; cursor: pointer;" @click="refreshPage()">Sayfayı Yenile</p>
          </div>
        </div>


        <div v-if="requests.length === 0" class="no-area">
          <img class="img" src="https://gcdn.bionluk.com/site/general/il-special-requests%402x.png">
          <p class="title">Özel <span>Alıcı İsteklerim</span></p>
          <p class="text">Aradığın hizmeti veya freelancer’ı bulmakta zorlanıyor musun? O zaman sen alıcı isteği oluştur, freelancer’lar seni bulsun!</p>

          <button @click="activateCreateSection" class="cuper-red-button" style="margin-top: 40px;">Alıcı İsteği Oluştur</button>
        </div>
        <div v-else class="request-list">

          <div style="  margin-left: 626px; margin-top: 30px;">
            <button style="position: absolute; width: 221px; font-size: 16px; font-weight: 600; min-width: 120px!important; padding-right: 30px; padding-left: 30px;"
                    class="cuper-red-button"
                    @click="activateCreateSection()">
              Yeni Alıcı İsteği Oluştur
            </button>
          </div>

          <p class="page-title">Özel <span>Alıcı İsteklerim</span></p>
          <p class="page-sub-title">Aradığın hizmeti veya freelancer’ı bulmakta zorlanıyor musun? O zaman sen alıcı isteği oluştur, freelancer’lar seni bulsun!</p>


          <div>
            <div class="request-box"  v-for="(request, index) in requests" :key="index" >
              <div :class="request.status === 1 ? 'head-active' : 'head'">
                <div style="display: flex; margin-left: 20px; align-items: center">
                  <img :src="user.avatar_url" class="avatar">
                  <div style="margin-left: 15px; display: flex; flex-direction: column; height: 46px; justify-items: center;">
                    <p class="username">{{user.username}}</p>
                    <p class="usertitle">{{user.info.title}}</p>
                  </div>

                </div>
                <div style="display: flex; align-items: center; margin-right: 30px;">
                  <div :class="'head-label ' + request.helpTextClass">
                    <router-link :to="'/panel/istekler/'+request.request_uuid+'/teklifler/'">{{request.helpText}}</router-link>
                  </div>
                  <div
                    :data-balloon="request.status === 1 ? 'Yayından Kaldır' : 'Tekrar Yayına Al'"
                    data-balloon-pos="up"
                    v-if="request.status === 1 || request.status === 2" @click="iconAction('status', request, index)" class="icon-container">
                    <img src="https://gcdn.bionluk.com/site/cicons/ic-eye-close.svg"  onload="SVGInject(this)" class="head-eye-icon">
                  </div>
                  <div
                    data-balloon="Sil"
                    data-balloon-pos="up"
                    @click="iconAction('delete',request,index)" class="icon-container">
                    <img src="https://gcdn.bionluk.com/site/cicons/ic-trash.svg"  onload="SVGInject(this)" class="head-delete-icon">
                  </div>
                  <div
                    data-balloon="Düzenle"
                    data-balloon-pos="up"
                    @click="iconAction('edit',request,index)" class="icon-container">
                    <img src="https://gcdn.bionluk.com/site/cicons/ic-edit-penci.svg"  onload="SVGInject(this)" class="head-edit-icon">
                  </div>

                </div>
              </div>
              <div class="body">
                <div class="breadcrumb" v-if="categories.find(({ id }) => id === request.category_id) && categories.find(({ id }) => id === request.category_sub_id)">
                  <span>{{ categories.find(({ id }) => id === request.category_id)['name'] }}</span>
                  <img style="margin-left: 10px; margin-right: 10px;" src="https://gcdn.bionluk.com/site/icon/tansel_sag_ok_gri.svg"/>
                  <span>{{ categories.find(({ id }) => id === request.category_sub_id)['name'] }}</span>
                  <template v-if="request.service_types && request.service_types.length">
                    <img style="margin-left: 10px; margin-right: 10px;" src="https://gcdn.bionluk.com/site/icon/tansel_sag_ok_gri.svg"/>
                    <span>{{request.service_types[0].name }}</span>
                  </template>
                  <span v-if="request.wordCount" class="extra-info-label">{{request.wordCount}} Kelime</span>
                </div>

                <p class="body-title">{{request.title}}</p>
                <div>

                </div>
                <p class="body-text" v-html="request.description"></p>
                <div v-for="upload in request.uploads" style="  padding: 5px 40px 15px; margin-top: 5px; margin-bottom: 8px; display: flex; align-items: center">
                  <img style="height: 27px; width: 21px; " :src="upload.icon_png">
                  <a target="_blank" :href="upload.url" :download="upload.filename" style="color: #2d3640; padding-left: 8px;" >{{ upload.filename }}</a>
                </div>

                <div style="display: flex;" v-if="request.meta_options_with_names && request.meta_options_with_names.length" class="request-meta_options">
                  <div style="margin-right: 30px;" v-for="meta_option in request.meta_options_with_names">
                    <p class="meta-option-title">{{meta_option.title}}</p>
                    <div>
                      <p class="meta-option-values" v-for="option in meta_option.options">{{option}}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div v-show="request.expandible" @click="expand(request, index)" class="read-more"><p>Devamını oku</p><p style="padding-top: 4px; padding-left: 4px;"><img src="https://gcdn.bionluk.com/site/cicons/ic-arrows-down.svg" onload="SVGInject(this)" class="read-more-icon"></p></div>
              <div class="foot">
                <div class="foot-date">
                  {{ Number(request.created_at) | customDateFormat('dd MMM yyyy') }}
                </div>
                <div class="foot-right">
                  <div class="item">
                    <img src="https://gcdn.bionluk.com/site/cicons/ic-finance.svg"  onload="SVGInject(this)" class="foot-icon">
                    <p class="foot-title">Bütçe: </p>
                    <p class="foot-value">{{request.budget}} TL</p>
                  </div>
                  <div class="item">
                    <img src="https://gcdn.bionluk.com/site/cicons/ic-timer.svg"  onload="SVGInject(this)" class="foot-icon">
                    <p class="foot-title">Süre: </p>
                    <p class="foot-value">{{request.duration}}</p>
                  </div>
                  <div class="item">
                    <img src="https://gcdn.bionluk.com/site/cicons/ic-special-offer.svg"  onload="SVGInject(this)" class="foot-icon">
                    <p class="foot-title">Teklifler: </p>
                    <p class="foot-value">{{request.offer_count}}<router-link v-if="request.offer_count" :to="'/panel/istekler/'+request.request_uuid+'/teklifler?tab=offers'"  class="foot-show">[göster]</router-link></p>
                  </div>

                </div>

              </div>
            </div>
          </div>


          <div v-if="seemorediv && !showLoader" style="margin-top:20px;display: flex;justify-content: center;padding: 20px 0;width: 849px">
            <a class="see-more-text" @click="getRequests()">Daha fazla göster +</a>
          </div>
          <div v-show="showLoader" style="margin-top:20px;width: 849px;">
            <div class="loader" style="text-align: center; margin:0 auto;"></div>
          </div>




        </div>

      </div>

      <div v-else style="margin-bottom: 200px;">

        <p @click="activateCreateRequest = false" class="cuper-black-button" style="cursor:pointer;width: 113px; height: 46px; margin-top:50px;margin-left:50px;">
          Geri Dön
        </p>

        <div class="create-area" v-if="step === 1">
          <p class="title">Ne tür bir işe ihtiyacın var?</p>
          <p class="sub-title">Hadi, başlayalım. 😎 İhtiyacın olan iş hangi ana kategoriye giriyor? Seçimini yap ve devam et!</p>

          <div class="main-cats-container">
            <div class="main-cats" v-for="cat in parentCategories">
              <div @click="selectCat(cat)" class="main-cats-bg" :style="cat.id === selectedCat.id ? 'color:white;background-image: url('+cat.square_png_hovered+')' : 'color:#5e6b79;background-image: url('+cat.square_png+')'">
                <p class="cat-title" :style="cat.id === selectedCat.id && cat.id === 3 ? 'color: #2d3640' : ''">{{cat.name}}</p>
                <p class="cat-sub-title" :style="cat.id === selectedCat.id && cat.id === 3 ? 'color: #2d3640' : ''"><span>{{cat.user_count | number_format}}</span> Freelancer</p>
                <p class="cat-sub-title" :style="cat.id === selectedCat.id && cat.id === 3 ? 'color: #2d3640' : ''"><span>{{cat.gig_count | number_format}}</span> İş İlanı</p>
              </div>
            </div>
          </div>
        </div>
        <div class="create-area" v-if="step === 2">
          <p class="title">Biraz daha detay alalım!</p>
          <p class="sub-title">Peki, ihtiyacın olan hizmet <span>{{selectedCat.name}}</span> kategorisinin hangi alanına giriyor?</p>

          <div class="step-2-container">
            <div class="header-img" :style="'background-image:url(https://gcdn.bionluk.com/site/cicons/catImg/squares/bgu_'+selectedCat.id+'.png)'">
              <div class="step-2-text-container" :style="selectedCat.id === 3  ? 'color:#2d3640' : 'color:white'">
                <p class="step-2-title">{{selectedCat.name}}</p>
                <p class="step-2-sub-title">Bu kategoride teklif alabileceğin <span>{{selectedCat.user_count | number_format}} freelancer</span> var.</p>
              </div>

              <div v-if="selectedCat.cat_users.length" style="display: flex; margin-right: 30px;">
                <div v-for="onlineUser in selectedCat.cat_users ">
                  <div>
                    <router-link
                      :to="'/'+onlineUser.username">
                      <img :alt="onlineUser.username" style="border:1px solid #bfc8d2; width: 44px; height: 44px; border-radius: 50%; margin-left: -15px;" :src="onlineUser.avatar_url">
                    </router-link>
                  </div>
                </div>
                <div style="width: 45px;height: 45px;border: solid 1px #ffffff;background-color: #eaedf2; border-radius: 50%; font-size: 14px; margin-left: -15px; display: flex; align-items: center; justify-content: center;letter-spacing: -0.43px; color: #2d3640; font-weight: bold">
                  +{{selectedCat.user_count - 5 }}
                </div>
              </div>

            </div>
            <div class="sub-cat-box-container">
              <div @click="selectSubCat(subCategory)" :class="selectedSubCat.id === subCategory.id ? 'sub-cat-box-active' : 'sub-cat-box'" v-for="(subCategory, ii) in selectedCat.subCategory">
                <span>{{subCategory.name}}</span>
              </div>
            </div>

            <div class="scroll-into-types"></div>
            <div id="sub-cat-type-kind-container" v-if="showStep2TypeArea">
              <div v-if="selectedSubCat.category_service_types && selectedSubCat.category_service_types.length" class="sub-cat-type-kind-container">
                <p class="title">Hizmet Türü</p>
                <div style="display: flex; align-items: flex-start">
                  <img style="margin-top: 7px;" src="https://gcdn.bionluk.com/site/cicons/bulb.svg">
                  <p class="help-text"><span>{{selectedSubCat.name}}</span> kategorisinde ne tür bir hizmet almak istiyorsun?</p>
                </div>

                <div class="sub-cat-box-container" style="margin-top: 10px">
                  <select class="super-drop-c" v-model="selectedServiceType" :style="selectedServiceType ? 'color:#2d3640;border-color:#00a575;margin-top: 0': 'margin-top: 0'">
                    <option :value="null" disabled style="color: #8b95a1">Seçin...</option>
                    <option :value="option.id" v-for="option in selectedSubCat.category_service_types">{{option.name}}</option>
                  </select>
                </div>
              </div>

              <div v-if="showMetaOptions"  class="sub-cat-type-kind-container">
                <p class="title">Temel Bilgiler</p>
                <div style="display: flex; align-items: flex-start">
                  <img style="margin-top: 7px;" src="https://gcdn.bionluk.com/site/cicons/bulb.svg">
                  <p class="help-text" >İyi teklifler alabilmek için alttaki bilgileri dikkatle doldurmalısın.</p>
                </div>


                <div class="meta-options-box">
                  <div class="meta-options-right">
                    <div v-if="reRender && !meta_option.dontShow" @click="selectMetaOptionBox(mindex)" v-for="(meta_option, mindex) in computedMetaOptions"  :key="mindex" :class="meta_option.selected ? 'meta-options-right-row-selected' : 'meta-options-right-row' " >
                      <div class="meta-options-right-row-text" :style="isMetaOptionsSelected(mindex) ? 'color:#5e6b79' : 'color: #fe5b5c;'">
                        <p>{{ meta_option.title_for_buyer }} {{isMetaOptionsSelected(mindex) ? '' : '*'}}</p>
                        <div class="check-icon-container" v-show="isMetaOptionsSelected(mindex)">
                          <img class="check-icon"
                               src="https://gcdn.bionluk.com/site/cicons/ic-check.svg"
                               onload="SVGInject(this)"/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="reRender" class="meta-options-left">

                    <p class="option-left-title">{{selectedMetaOption.description_for_buyer}} <span style="font-weight: 500">{{selectedMetaOption && selectedMetaOption.info && selectedMetaOption.info.max ? '(en fazla '+selectedMetaOption.info.max +' seçenek)' : ''}}</span></p>
                    <div v-if="selectedMetaOption && selectedMetaOption.info" class="meta-options-left-form">
                      <template v-if="selectedMetaOption.info.type === 'radio'">
                        <div @click="selectMetaOption(oindex)" v-for="(option, oindex) in selectedMetaOption.options" class="filter-radio-item">
                          <div :class="option.selected ? 'filter-radio-full' : 'filter-radio-empty'"></div>
                          <p :class="option.selected ? 'filter-radio-label-active' : 'filter-radio-label' ">{{option.title_for_buyer}}</p>
                        </div>
                      </template>
                      <template v-if="selectedMetaOption.info.type === 'checkbox'">
                        <div @click="selectMetaOption(oindex)" v-for="(option, oindex) in selectedMetaOption.options"  class="filter-checkbox-item" style="display: flex" >
                          <custom-check
                            :container-style="'width:20px; height:20px;'"
                            :check-mark-style="'width:20px; height:20px; border-radius:4px; border: solid 1px #8b95a1;'"
                            called-from=""
                            :single-gig="{}"
                            :id="option.id + '__' + option.title"
                            :checked="option.selected"
                            :disabled="!option.selected && isMetaOptionsDisabled()"
                            :value="option.selected"
                            @input="selectMetaOption(oindex)"
                          />
                          <div :class="option.selected ? 'filter-checkbox-label-active' : isMetaOptionsDisabled() ? 'filter-checkbox-label-disabled' : 'filter-checkbox-label'">{{option.title_for_buyer}}</div>
                        </div>
                      </template>
                      <template v-if="selectedMetaOption.info.type === 'selectbox'">
                        <div style="margin-top: 30px">
                          <select id="sel" class="super-drop" v-model="dummyMetaOption">
                            <option :value="null" disabled style="color: #8b95a1">Seç</option>
                            <option :value="option.id" v-for="(option, oindex) in selectedMetaOption.options">{{option.title_for_buyer}}</option>
                          </select>
                        </div>
                      </template>
                    </div>



                  </div>
                </div>


              </div>


            </div>


          </div>
        </div>
        <div class="create-area" v-if="step === 3">



          <template v-if="selectedSubCat.id === 38">
            <p class="title">Çeviri Detayları ve Kelime Sayısı</p>
            <p class="sub-title">Şimdi sıra işin detaylarında. 🙂 Çeviri istediğin metnin kaç kelime olduğunu belirtirsen teklif verecek freelancerlardan en uygun fiyatları alma şansın yükselecektir.</p>

            <p style="margin-top: 60px; margin-bottom: 20px; font-weight: 500; color: #2d3640; font-size: 26px">Toplam Kelime Sayısı</p>
            <div style="display: flex; justify-content: space-between">
              <input @focusout="handleFocusOutWord" maxlength="8" :style="wordCount ? 'border: solid 1px #00a575;' : ''" v-model="wordCount" class="cuper-input" placeholder="Toplam kelime sayısını gir">
              <div style="width: 380px;">
                <div style="display: flex; align-items: flex-start">
                  <img style="margin-top: 5px;" src="https://gcdn.bionluk.com/site/cicons/bulb.svg">
                  <div style="margin-left:5px;color: #4b4f52; line-height: 1.71;font-weight: 300;font-size: 14px;">
                    <p>Çevirisini yaptırmak istediğin metnin kaç kelimeden oluştuğunu bilmiyorsan buradan yararlanabilirsin.</p>
                    <a target="_blank" href="https://charcount.com" rel="nofollow" style="color: #00a575;font-weight: 500; margin-top: 5px;">https://charcount.com</a>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-if="selectedSubCat.id === 61">

            <p class="title">Seslendirmen Detayları ve Kelime Sayısı</p>
            <p class="sub-title">Şimdi sıra işin detaylarında. 🙂 Seslendirilmesini istediğin metnin kaç kelime olduğunu belirtirsen teklif verecek freelancerlardan en uygun fiyatları alma şansın yükselecektir.</p>


            <p style="margin-top: 60px; margin-bottom: 20px; font-weight: 500; color: #2d3640; font-size: 26px">Toplam Kelime Sayısı</p>
            <div style="display: flex; justify-content: space-between">
              <input @focusout="handleFocusOutWord" maxlength="8" :style="wordCount ? 'border: solid 1px #00a575;' : ''" v-model="wordCount" class="cuper-input" placeholder="Toplam kelime sayısını gir">
              <div style="width: 380px;">
                <div style="display: flex; align-items: flex-start">
                  <img style="margin-top: 5px;" src="https://gcdn.bionluk.com/site/cicons/bulb.svg">
                  <div style="margin-left:5px;color: #4b4f52; line-height: 1.71;font-weight: 300;font-size: 14px;">
                    <p>Seslendirilmesini istediğin metnin kaç kelimeden oluştuğunu bilmiyorsan buradan yararlanabilirsin.</p>
                    <a target="_blank" href="https://charcount.com" rel="nofollow" style="color: #00a575;font-weight: 500; margin-top: 5px;">https://charcount.com</a>

                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="create-area" v-if="step === 4">
          <p class="title">Bütçe ve Süre</p>
          <p class="sub-title">Çok az kaldı! Bu ihtiyacın için tahmini bütçeni ve bu işin ne kadar zaman içinde sana teslim edilmesi gerektiğini belirtmelisin.</p>
          <p style="margin-top: 60px; margin-bottom: 10px; font-weight: 500; color: #2d3640; font-size: 26px">Tahmini bütçen</p>
          <p style="color:#5e6b79; font-size: 16px; margin-bottom: 30px;">
            <img style="margin-top: 5px;" src="https://gcdn.bionluk.com/site/cicons/bulb.svg">
            Proje için ayırdığın bütçeyi tahmini, <span style="font-weight: 600; color: #2d3740">fakat gerçekçi</span> olarak belirtmen freelancerların ilgisini çekmek için önemli.
          </p>
          <input @focusout="handleFocusOut" @focusin="handleFocusIn" maxlength="5" :style="budgetText ? 'border: solid 1px #00a575;' : ''" v-model="budgetText" class="cuper-input" placeholder="Tahmini bütçeni gir (₺)">
          <p style="margin-top: 60px; margin-bottom: 20px; font-weight: 500; color: #2d3640; font-size: 26px">Teslim süresi</p>
          <p style="color:#5e6b79; font-size: 16px; margin-bottom: 10px;">
            <img style="margin-top: 5px;" src="https://gcdn.bionluk.com/site/cicons/bulb.svg">
            Proje için belirleyeceğin süreyi <span style="font-weight: 600; color: #2d3740">gerçekçi ve olası gecikmeleri</span> de düşünerek vermen önemli.
          </p>
          <div class="sub-cat-box-container" style="margin-top: 0px;">
            <div @click="selectDuration(request_duration)" :class="duration === request_duration.id ? 'sub-cat-box-active' : 'sub-cat-box'"  v-for="(request_duration, index) in $store.state.request_durations">
              <span>{{request_duration.name}}</span>
            </div>
          </div>
        </div>
        <div class="create-area" v-if="step === 5">
          <p class="title">Son Bir Dokunuş Kaldı!</p>
          <p class="sub-title">Neredeyse bitti! Bu süreçte en iyi teklifleri alabilmek için, alıcı isteğinle ilgili freelancer’ların ihtiyaç duyacağı detayları yazman, varsa görsel, ses veya video örneklerini paylaşmalısın.</p>
          <p style="margin-top: 60px; margin-bottom: 10px; font-weight: 500; color: #2d3640; font-size: 26px">Yaptırmak istediğin işin detayları</p>
          <p style="color:#5e6b79; font-size: 16px; margin-bottom: 20px;">
            <img style="margin-top: 5px;" src="https://gcdn.bionluk.com/site/cicons/bulb.svg">
            İhtiyacın olan işle ilgili detayları ve freelancer’ların bilmesi gerekenleri <span style="font-weight: 600; color: #2d3740">sade ve anlaşılır biçimde</span> iletmen önemli.
          </p>
          <input :style="title ? 'border: solid 1px #00a575;width:843px;' : 'width:843px;'" v-model="title" class="cuper-input data-hj-allow" placeholder="Alıcı isteğine etkileyici bir başlık gir…">
          <textarea v-model="description" style="margin-top: 20px; width: 843px; height: 200px;" class="c-text-area data-hj-allow" placeholder="Örnek Metin:
5.000 Kelimeden oluşan bir tıp makalesinin Türkçe’den, İngilizce’ye çevrilmesine ihtiyacım var. 2 hafta içerisinde teslim edilmesi gerekmekle birlikte daha önce tıp çevirisi yapmış arkadaşların teklif vermesini tercih ederim. Makalenin 1 paragraflık kısmına eklerden ulaşabilirsin."></textarea>

          <p style="margin-top: 60px; margin-bottom: 10px; font-weight: 500; color: #2d3640; font-size: 26px">Örnek dosyalar</p>
          <p style="color:#5e6b79; font-size: 16px; margin-bottom: 20px;">
            <img style="margin-top: 5px;" src="https://gcdn.bionluk.com/site/cicons/bulb.svg">
            İşle ilgili varsa, <span style="font-weight: 600; color: #2d3740">örnek görsel, metin, ses veya video</span> eklemen freelancerların işi daha rahat anlamalarını sağlar.
          </p>


          <div class="upload-area-container">
            <div v-if="!dragAndDropArea" class="upload-area" >
              <p class="upload-title">Sürükle & Bırak</p>
              <p class="upload-sub-title">Bu alana eklemek istediğin dosyaları sürükleyip bırakabilirsin.</p>
              <p class="upload-or">YA DA</p>
              <div style="margin: 20px auto 0;width: 220px;">
                <button @click="chooseFiles('request_upload')" style="width: 220px; height: 54px;" class="cuper-black-button">
                  <img src="https://gcdn.bionluk.com/site/cicons/ic-round-plus.svg"  onload="SVGInject(this)" class="upload-plus-icon">
                  <span style="padding-left: 14px;">Dosya Ekle</span>
                </button>
              </div>
              <p class="upload-foot-title">Ekleyebileceğin dosya tipleri:</p>
              <p class="upload-foot-sub-title">.DOC, .XLS .TXT, JPG, PNG, .GIF, PDF, .MP4, .MP3, .MOV, .MPEG, .Sketch, .XD, .PSD</p>
            </div>
            <div  v-else
                  id="request-drag-drop"
                  draggable="true"
                  @drop="dropHandler($event)"
                  @dragover="dragOverHandler($event)"
            >
              {{dragDropText}}
            </div>

            <div class="upload-area-files">
              <div v-if="requestFiles.length" style="display: flex; justify-content: space-between">
                <p class="upload-files-title">YÜKLENEN</p>
                <p class="upload-file-count">{{requestFiles.length}} dosya</p>
              </div>

              <div v-for="file in requestFiles" style="margin-top: 45px;">
                <div style="display: flex; align-items: center; justify-content: space-between">
                  <div style="display: flex; align-items: center">
                    <div v-if="file.url"> <img src="https://gcdn.bionluk.com/site/cicons/ic-check.svg" onload="SVGInject(this)" class="check-icon"> </div>
                    <img v-if="file.icon_png" style="height: 41px; width: 32px; padding-left: 22px; " :src="file.icon_png">
                    <img v-else-if="$store.state.percentCompleted.temp_icon_png[file.stamp]" style="height: 41px; width: 32px; padding-left: 22px; " :src="$store.state.percentCompleted.temp_icon_png[file.stamp]">
                    <div style="display: flex; flex-direction: column; margin-left: 15px;">
                      <a target="_blank" :href="file.url" style="color: #2d3640; font-weight: 500; font-size: 16px; text-overflow: ellipsis; max-width: 240px; overflow: hidden; white-space: pre">{{ file.filename }}</a>
                      <progress v-if="$store.state.percentCompleted.request[file.stamp]" style="margin-top: 5px; margin-left: 0;" class="progressbar-purple" :value='$store.state.percentCompleted.request[file.stamp]' max="100"></progress>
                      <p style="color:#00a575;font-size: 14px; font-weight: 500;margin-top: 5px;">%{{parseInt($store.state.percentCompleted.request[file.stamp]) || 100}} Tamamlandı <span style="color: #8b95a1;">· {{formatBytes(file.filesize)}}.</span></p>
                    </div>
                  </div>


                  <div v-show="!file.url" @click="cancelUpload(file.stamp)" class="icon-area">
                    <img  src="https://gcdn.bionluk.com/site/cicons/ic-delete.svg" onload="SVGInject(this)" class="delete-icon">
                  </div>
                  <div v-show="file.url" @click="deleteFile(file.externalID)" class="icon-area">
                    <img  src="https://gcdn.bionluk.com/site/cicons/ic-trash.svg" onload="SVGInject(this)" class="delete-uploaded-file-img">
                  </div>

                </div>
              </div>

            </div>
          </div>
          <input id="request_upload" style="visibility: hidden" type="file" @change="onFileChange($event)" multiple>





        </div>

        <template v-if="1===2 && step === 5 && !hide_is_plus_area">

          <div style="margin-top: 30px;  margin-left:60px; margin-right: 30px; ">

            <p style=" margin-bottom: 10px; font-weight: 500; color: #2d3640; font-size: 26px">Alıcı isteği kimlere gösterilsin</p>
            <p style="color:#5e6b79; font-size: 16px; margin-bottom: 20px;">
              <img style="margin-top: 5px;" src="https://gcdn.bionluk.com/site/cicons/bulb.svg">
              Her iki seçenek için de bu aşamada herhangi bir ödeme yapmayacak olduğunu hatırlatırız.
            </p>

            <div style="display: flex; justify-content: space-between; ">
              <div @click="switchPlus(false)" class="plus-container" :class="!is_plus ? 'active' : ''">
                <div>
                  <img src="https://gcdn.bionluk.com/site/cicons/ilSearch%402x.png">
                </div>
                <div style="margin-left: 20px; margin-top: 25px; width: 230px;">
                  <p class="title">Tüm freelancerlar görsün</p>
                  <p class="text">Alıcı isteğin tüm freelancer’lara gösterilir. Gelen teklifler arasında ihtiyacına en uygun freelancer’ı kendin belirler ve anlaşmaya varırsanız siparişi başlatırsın.</p>
                </div>
              </div>
              <div @click="switchPlus(true)" class="plus-container" :class="is_plus ? 'active' : ''">
                <div>
                  <img src="https://gcdn.bionluk.com/site/cicons/ilBestFreelancers%402x.png">
                </div>
                <div style="margin-left: 20px; margin-top: 25px; width: 230px;">
                  <p class="title">Bionluk Plus’ı dene</p>
                  <p class="text">Alıcı isteğin sadece Bionluk’un tavsiye ettiği ve güvendiği freelancer’lara gösterilir ve sipariş süreci boyunca karşılacağın tüm olası problemlerde sana özel <span>Bionluk Müşteri Temsilcin</span> destek için hazır olur.</p>

                </div>

              </div>
            </div>

            <div v-show="is_plus" class="u-plus-box">
              <div class="left-b">
                <img style="width: 155px;" src="https://gcdn.bionluk.com/site/cicons/ilplus.png"/>

                <div style="margin-top: 20px; width: 100%; ">
                  <p class="price">29.00<span style="padding-left: 3px">₺</span></p>
                  <p style="font-size: 38px; color:#2D3640; margin-top: 15px; font-weight: bold; letter-spacing: -0.5px;">Ücretsiz</p>
                </div>

                <div style="display: flex; align-items: flex-start; width: 185px; text-align: left; margin: 30px auto;">
                  <img style="margin-top: 5px;" src="https://gcdn.bionluk.com/site/cicons/bulb.svg">
                  <div style="margin-left:5px;  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  color: #5e6b79;">
                    Bu adımda <span style="font-weight: bold;color: #2d3640;">herhangi bir ücret ödemeyeceksin!</span> Hizmet bedeli sipariş onay aşamasında tahsil edilir.
                  </div>
                </div>
              </div>
              <div class="right-b">
                <p style="display:flex; justify-content: center; align-items: center; height: 16px; padding-bottom: 2px;  width: 40px; color: #fff; text-align: center;  border-radius: 10px;
  background-color: #26ca9a;  letter-spacing: -0.22px; font-size: 11px;">Yeni</p>
                <p class="title">Bionluk <span>Plus</span></p>
                <p class="sub-title">
                  <span>Sadece 500 TL ve üzeri</span> alıcı isteği oluşturan üyelerimize özel olarak verilen ayrılacılıklı bir hizmet çeşididir.
                </p>

                <div class="li-container">
                  <div class="li-row">
                    <div class="check-icon-container">
                      <img class="check-icon" src="https://gcdn.bionluk.com/site/cicons/ic-check.svg" onload="SVGInject(this)"/>
                    </div>
                    <p class="li">Alıcı isteğin Bionluk ekibi tarafından incelenir, <span>fiyat ve süre analizi</span> yapılır.</p>
                  </div>
                  <div class="li-row">
                    <div class="check-icon-container">
                      <img class="check-icon" src="https://gcdn.bionluk.com/site/cicons/ic-check.svg" onload="SVGInject(this)"/>
                    </div>
                    <p class="li">Alıcı isteğin sadece alanında uzman ve en iyi freelancerlara gösterilir.</p>
                  </div>
                  <div class="li-row">
                    <div class="check-icon-container">
                      <img class="check-icon" src="https://gcdn.bionluk.com/site/cicons/ic-check.svg" onload="SVGInject(this)"/>
                    </div>
                    <p class="li">İlanına teklifler gelmeye başladığı anda <span>e-posta ile bilgilendirilirsin.</span></p>
                  </div>
                  <div class="li-row">
                    <div class="check-icon-container">
                      <img class="check-icon" src="https://gcdn.bionluk.com/site/cicons/ic-check.svg" onload="SVGInject(this)"/>
                    </div>
                    <p class="li">Gelen teklifleri <span>fiyat ve zaman</span> olarak sıralayabilirsin.</p>
                  </div>
                  <div class="li-row">
                    <div class="check-icon-container">
                      <img class="check-icon" src="https://gcdn.bionluk.com/site/cicons/ic-check.svg" onload="SVGInject(this)"/>
                    </div>
                    <p class="li">Siparişin süresince, <span>Bionluk Müşteri Temsilcin</span> destek için yanında olacak.</p>
                  </div>
                  <div class="li-row">
                    <div class="check-icon-container">
                      <img class="check-icon" src="https://gcdn.bionluk.com/site/cicons/ic-check.svg" onload="SVGInject(this)"/>
                    </div>
                    <p class="li">Freelancer ile yaşanılacak olası anlaşmazlık ve sipariş iptal durumunda <span>Bionluk Plus hizmet bedeli iadesi garanti!</span></p>
                  </div>
                </div>

              </div>
            </div>
          </div>


        </template>

      </div>

    </div>
    <div class="bottom-fixed-div" v-if="activateCreateRequest">
      <div class="bottom-centered">
        <div style="display: flex">
          <div style="width: 300px;">
            <p class="step-title">Adım {{step}} / 5</p>
            <p class="step-sub-title">{{stepName}}</p>
          </div>
          <div v-if="selectedCat.id" style="display: flex; height: 46px; margin-right:20px; align-items: center">
            <div  @click="goStep(1)" class="bottom-cat-img-container" :style="'background:'+selectedCat.cat_color">
              <img class="bottom-cat-img" :src="'https://gcdn.bionluk.com/site/cicons/catImg/squares/sm_'+selectedCat.id+'.png'">
            </div>
            <p @click="goStep(1)" class="bottom-cat-title">{{selectedCat.name}}</p>
          </div>
          <div @click="goStep(2)" v-if="selectedSubCat.id" style="cursor:pointer;display:flex; align-items:center;margin-right:20px; font-size:14px; padding-left: 20px; padding-right: 20px; color:#ffffff;height: 46px;border-radius: 5px;background-color: rgba(255, 255, 255, 0.1);">
            <div style="display: flex;justify-content: center;">
              <p >{{selectedSubCat.name}}</p>
              <div v-if="showTooltip" style="position:absolute;width: 178px; height: 55px;margin-top: -120px;">
                <div class="arrow_box"><p style="padding-left: 15px; padding-right: 15px;">Geri dönerek düzenleme yapmak için burdaki adımları kullanabilirsin.</p></div>
                <div class="a-outer"><div class="a-inner">&nbsp;</div></div>
              </div>
            </div>
          </div>
          <div @click="goStep(3)" v-if="selectedServiceKind" style="cursor:pointer;display:flex; align-items:center;margin-right:20px; font-size:14px; padding-left: 20px; padding-right: 20px; color:#ffffff;height: 46px;border-radius: 5px;background-color: rgba(255, 255, 255, 0.1);">
            {{ selectedSubCat.category_service_kinds.options.find(({ id }) => id === selectedServiceKind)['name']}}
          </div>
          <div @click="goStep(3)" v-if="selectedServiceType" style="cursor:pointer;display:flex; align-items:center;margin-right:20px; font-size:14px; padding-left: 20px; padding-right: 20px; color:#ffffff;height: 46px;border-radius: 5px;background-color: rgba(255, 255, 255, 0.1);">
            {{ selectedSubCat.category_service_types.find(({ id }) => id === selectedServiceType)['name']}}
          </div>
          <div @click="goStep(3)" v-if="wordCount" style="cursor:pointer;display:flex; align-items:center;margin-right:20px; font-size:14px; padding-left: 20px; padding-right: 20px; color:#ffffff;height: 46px;border-radius: 5px;background-color: rgba(255, 255, 255, 0.1);">
            {{wordCount}} kelime
          </div>
          <div @click="goStep(4)" v-if="budget" style="cursor:pointer;display:flex; align-items:center;margin-right:20px; font-size:14px; padding-left: 20px; padding-right: 20px; color:#ffffff;height: 46px;border-radius: 5px;background-color: rgba(255, 255, 255, 0.1);">
            {{budget}}₺
          </div>
          <div @click="goStep(4)" v-if="duration" style="cursor:pointer;display:flex; align-items:center;margin-right:20px; font-size:14px; padding-left: 20px; padding-right: 20px; color:#ffffff;height: 46px;border-radius: 5px;background-color: rgba(255, 255, 255, 0.1);">
            {{ $store.state.request_durations.find(({ id }) => id ===  duration)['name'] }}
          </div>
        </div>
        <div @click="nextStep" :class="enableButton ? 'continue-button-active' : 'continue-button'">
          {{step === 5 ? 'Bitir ve Yayınla' : 'Devam Et'}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import dropdown from 'vue-my-dropdown';
  import Multiselect from 'vue-multiselect'
  import CustomCheck from "@/pages/components/CustomCheck";

  export default {
    name: "src-pages-body-workstation-requests-list-v3",
    components: {
      dropdown,
      Multiselect,
      CustomCheck
    },

    data() {
      return {

        reRender:true,
        hideTooltip:false,
        showLoader:false,
        seemorediv:false,
        buttonLoading:false,
        pageLoaded:false,
        activateCreateRequest:false,
        requests:[],
        totalCount:0,
        offset:0,
        selectedCat:{},
        selectedSubCat:{},
        selectedServiceKind:null,
        selectedServiceType:null,
        wordCount:null,
        budget:null,
        budgetText:null,
        duration:null,
        title:'',
        description:'',
        step:1,
        is_plus:false,
        hide_is_plus_area:false,

        requestFiles:[],
        dragAndDropArea:false,
        dragDropText: 'Dosyaları bu alana sürükle.',
        selectedMetaOption:{},
        dummyMetaOption:null,
      }
    },
    methods: {

      selectMetaOption(i = null) {
        let selectedMetaOption = this.selectedMetaOption;
        const foundIndex = this.selectedSubCat.meta_options.findIndex(option => option.selected)
        let currentOptions = this.selectedSubCat.meta_options[foundIndex].options;

        let count = 0;
        currentOptions.forEach(co => {
          if(co.selected){
            count++;
          }
        });

        currentOptions.forEach((o,oi) => {
          if(selectedMetaOption?.info.type === 'radio'){
            if(i === oi){
              o.selected = !o.selected
            } else {
              o.selected = false;
            }
          } else if(selectedMetaOption?.info.type === 'checkbox'){
            if(i === oi){
              if(selectedMetaOption?.info?.max){
                if(!o.selected){
                  if(selectedMetaOption.info.max > count){
                    o.selected = true;
                  }
                } else {
                  o.selected = false;
                }
              } else {
                o.selected = !o.selected;
              }
            }
          } else if(selectedMetaOption?.info.type === 'selectbox'){
            o.selected = o.id === i;
          }
        });
        this.reRender = false;
        this.reRender = true;
      },

      isMetaOptionsSelected(i){
        let isSelected = false;
        this.selectedSubCat.meta_options.forEach((o,oi) => {
          o.options.forEach( (oo,ooi) => {
            if(i === oi && oo.selected){
              isSelected = true
            }
          })
        });
        return isSelected;
      },

      isMetaOptionsDisabled(){
        let isDisabled = false;

        const foundIndex = this.selectedSubCat.meta_options.findIndex(option => option.selected)
        if (foundIndex > -1 && this.selectedSubCat.meta_options[foundIndex]?.info?.max) {
          let currentOptions = this.selectedSubCat.meta_options[foundIndex].options;
          if (currentOptions) {
            let count = 0;
            currentOptions.forEach(oo => {
              if(oo.selected){
                count++;
              }
            })
            if(this.selectedSubCat.meta_options[foundIndex].info.max <= count){
              isDisabled = true;
            }
          }
        }


        return isDisabled;

      },

      selectMetaOptionBox(index){
        this.selectedSubCat.meta_options.forEach((m,i) => {
          if(index === i){
            m.selected = true;
            this.selectedMetaOption = m;
          } else {
            m.selected = false;
          }

        });

        this.reRender = false;
        this.reRender = true;
      },

      switchPlus(plus){
        if(plus){
          if(this.budget && this.budget > 499){
            this.is_plus = plus;
          } else {
            this.$toasted.global.errorToast({description: 'Bionluk Plus servisimiz sadece 500TL ve üzeri alıcı isteklerinde geçerlidir.'});
          }
        } else {
          this.is_plus = plus;
        }

        this.is_plus = false;
      },


      expand(request, index){
        let elems = document.getElementsByClassName("body");
        elems[index].style.height = request.expandible+'px';
        elems[index].style.overflow = 'visible';
        const requests = JSON.parse(JSON.stringify(this.requests));
        requests[index].expandible = false
        this.requests = requests;
      },


       isOverflown(element) {
        if(element){
          return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
        } else {
          return  false;
        }
      },

      changeRequestStatus(receivedRequest, status) {

          this.api.user.changeRequestStatus(receivedRequest.request_uuid, status, this.$Progress)
            .then(({data}) => {
              let result = data;
              if (result.success) {
                this.$toasted.global.infoToast({description: result.message});
                this.init();
              } else {
                this.$toasted.global.errorToast({description: result.message});
              }
            })
            .catch(err => {
              this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            });
      },

      iconAction(action, request, index){
        if(action === 'edit'){
          this.$router.push('/panel/istekler/' + request.request_uuid +'/duzenle')
        }
        if(action === 'status'){
          let new_status = request.status === 1 ? 2 : 1;
          this.api.user.changeRequestStatus(request.request_uuid, new_status, this.$Progress)
            .then(({data}) => {
              let result = data;
              if (result.success) {
                this.$toasted.global.infoToast({description: result.message});
                this.init();
              } else {
                this.$toasted.global.errorToast({description: result.message});
              }
            })
            .catch(err => {
              this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            });

        }

        if(action === 'delete'){
          this.requests.splice(index, 1);
          this.api.user.changeRequestStatus(request.request_uuid, -1, this.$Progress)
            .then(({data}) => {
              let result = data;
              if (result.success) {
                this.$toasted.global.infoToast({description: result.message});
              } else {
                this.$toasted.global.errorToast({description: result.message});
              }
            })
            .catch(err => {
              this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            });
        }

      },
      createRequest() {
        //this.buttonLoading = true;

        let selectedSkill = "";

        let uploadResults = "";
        if (this.requestFiles.length > 0) {
          this.requestFiles.forEach(function (uploadResult, index) {
            uploadResults = uploadResults + (index === 0 ? "" : ",") + uploadResult.externalID;
          });
        }

        let selectedMetaOptions = [];
        this.selectedSubCat.meta_options.forEach(o => {
          let selectedOptions = [];
          let hasIt = false;
          o.options.forEach(oo => {
            if(oo.selected){
              hasIt = true;
              selectedOptions.push(oo.id)
            }
          })
          if(hasIt){
            selectedMetaOptions.push({
              id:o.id,
              options:selectedOptions
            })
          }
        });

        let status = 1;
        this.api.request.create(this.title, this.description, this.selectedCat.id, this.selectedSubCat.id, null, this.duration, this.selectedServiceType, this.selectedServiceKind, status, selectedSkill, uploadResults, this.budget, this.wordCount, this.is_plus ? '1' : '-1', JSON.stringify(selectedMetaOptions))
          .then(({data}) => {
            let result = data;
            if (result.success) {
              this.buttonLoading = false;
              this.deactivateCreateSection();
              if (result.data.redirect_url) {
                this.$router.push(result.data.redirect_url);
              } else {
                this.init();
              }
              //this.$toasted.global.infoToast({description: result.message});
              window.scrollTo(0, 0);
            } else {
              this.buttonLoading = false;
              this.$toasted.global.errorToast({description: result.message});
            }
          }).catch(err => {
          this.buttonLoading = false;
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
        });
      },


      handleFocusOutWord(){
        if(isNaN(this.wordCount)){
          this.wordCount = null;
        } else {
          this.wordCount = this.wordCount ? this.wordCount.replace(".", "") : '';
        }
      },

      handleFocusOut(){
        if(isNaN(this.budgetText)){
          this.budgetText = '';
          this.budget = null;
        } else {
          this.budgetText = this.budgetText.replace(".", "");
          this.budget = this.budgetText;
          if(this.budget < 500){
            this.is_plus = false;
          }

          if(this.budget >= 500 && !this.hide_is_plus_area){
            //this.is_plus = true;
            this.is_plus = false;
          }

          if(this.budget < 100){
            this.budgetText = '';
            this.budget = null;
            this.$toasted.global.errorToast({description: 'Tahmini bütçen minimum 100 TL olmalı.'});
          } else {
            this.budgetText += ' ₺';
          }

        }
      },
      handleFocusIn(){
        this.budgetText = this.budgetText.replace(' ₺', '');
      },

       formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
      },

      chooseFiles(id) {
        document.getElementById(id).click()
      },

      cancelUpload(timeStamp){
        this.cancelUploadRequest(parseInt(timeStamp));
        let requestFileIndex = this.requestFiles.findIndex(f => f.stamp === timeStamp);
        if(requestFileIndex !== -1){
          this.requestFiles.splice(requestFileIndex, 1);
        }
      },
      deleteFile(uploadID) {
        this.api.general.updateUploadStatus(uploadID, this.Constants.UPLOAD_STATUSES.DELETE)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.requestFiles = this.requestFiles.filter(function (file) {
                return file.externalID !== uploadID
              });

              this.$toasted.global.infoToast({description: 'Silindi'});
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            console.log(err);
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },
      onFileChange(e) {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;


        let timeStamp = +new Date();

        for (let i = 0; i < files.length; i++) {

          let rand = Math.floor(Math.random() * 1000) + 1;
          timeStamp = timeStamp + rand + i;
          this.requestFiles.push({
            url: null,
            externalID: timeStamp,
            filename: files[i].name,
            stamp: timeStamp,
            filesize:files[i].size
          });


          this.uploadFileToGoogle(files[i], this.Constants.UPLOAD_TYPES.REQUEST,null, timeStamp)
            .then(result => {

              let requestFileIndex = this.requestFiles.findIndex(f => f.filename === files[i].name);
              let requestFile = this.requestFiles[requestFileIndex];
              requestFile.externalID = result.externalID;
              requestFile.url = this.Constants.CDN_UPLOADS_G + result.uploadName;
              requestFile.icon_png = result.icon_png;
              this.requestFiles[requestFileIndex] = requestFile;



            }).catch(err => {
              console.log(err);
            this.$store.state.percentCompleted.request[timeStamp] = 0;
            this.$toasted.global.infoToast({description: err ? err.message : this.Constants.TEXTS.SERVER_ERROR_MESSAGE});

          });
        }

      },

      deleteUploadedFile(file) {
        this.requestFiles = this.requestFiles.filter(({ externalID }) => externalID !== file.externalID)
      },

      dragStartEvent(event) {
        this.dragAndDropArea = true;
        let dragDrop = document.getElementById('request-drag-drop');
        if (event.target.id === "request-drag-drop") {
          this.dragDropText = 'Dosyaları bu alana bırakın.';
        }

      },

      dragLeaveEvent(event) {

        let dragDrop = document.getElementById('request-drag-drop');
        if (event.originalEvent && (!event.originalEvent.clientX && !event.originalEvent.clientY)) {
          //outside body / window
          this.dragAndDropArea = false;
        } else if ((!event.clientX && !event.clientY) || (!event.pageX && !event.pageY)) {
          this.dragAndDropArea = false;
        }

        else if (event.target.id === "request-drag-drop") {
          this.dragDropText = 'Dosyaları bu alana sürükle.';
        }
      },

      dropHandler(event) {
        this.EventBus.$emit('dragAndDropFile', null);

        event.preventDefault();
        event.stopPropagation();

        this.onFileChange(event);
        let dragDrop = document.getElementById('request-drag-drop');
        this.dragAndDropArea = false;


      },

      dragOverHandler(event) {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';  // See the section on the DataTransfer object.
      },


      selectDuration(duration){
        this.duration = duration.id;
      },
      goStep(step){
        if(step === 2 && this.showTooltip){
          this.hideTooltip = true
        } else {
          this.step = step;
          window.scrollTo(0,0);
        }

      },
      nextStep(){
        if(this.enableButton){
          if(this.step === 5){
            this.createRequest();
          } else {
            if(this.step === 2){
              if((this.selectedSubCat.id === 38 || this.selectedSubCat.id === 61 )){ //çeviri ve seslendirme manual şimdilik
                this.step = 3
              } else {
                this.step = 4
              }
            } else {
              this.step++;
            }
            window.scrollTo(0,0);
          }
        }
      },

      selectCat(cat){
        this.selectedSubCat = {};
        this.selectedServiceKind = null;
        this.selectedServiceType = null;
        this.wordCount = null;
        this.title = '';
        this.description = '';
        this.budgetText = '';
        this.budget = null;
        this.duration = null;
        this.selectedCat = cat;
        this.dummyMetaOption = null;
        if(cat.id === 6){
          this.hide_is_plus_area = true
          this.is_plus = false;
        } else {
          this.hide_is_plus_area = false
        }
      },
      selectSubCat(subCat){

        if(this.selectedSubCat && this.selectedSubCat.id && subCat && subCat.id && this.selectedSubCat.id === subCat.id){

        } else {
          this.selectedServiceKind = null;
          this.selectedServiceType = null;
          this.wordCount = null;
          this.title = '';
          this.description = '';
          this.budgetText = '';
          this.budget = null;
          this.duration = null;

          this.dummyMetaOption = null;

          if(subCat.id === 61){
            subCat.category_service_types = [];
          }

          this.selectedSubCat = subCat;


          this.$nextTick(() => {
            if(subCat && ((subCat.category_service_types && subCat.category_service_types.length > 0) || (subCat.meta_options && subCat.meta_options.length > 0))){

              if(subCat.meta_options && subCat.meta_options && subCat.meta_options.length > 0){

                this.selectedSubCat.meta_options.forEach((c,i) => {
                  c.options.forEach((m) => {
                    m.selected = false;
                  })
                  if(i===0){
                    this.selectedMetaOption = c;
                    c.selected = true;
                  } else {
                    c.selected = false;
                  }
                });

                this.reRender = false;
                this.reRender = true;
              }
              document.querySelector('.scroll-into-types').scrollIntoView({
                behavior: 'smooth'
              });
            }
          });
        }


      },
      refreshPage() {
        location.reload();
      },


      getRequests() {

        this.showLoader = true;
        this.api.request.myRequests(10, this.offset)
          .then(({data}) => {
            let result = data;
            this.showLoader = false;
            if (result.success) {
              this.requests = this.requests.concat(result.data.requests);
              this.totalCount = result.data.totalCount;
              this.seemorediv = result.data.seemorediv;
              this.offset += 10;


            } else {
              //this.$router.push("/");
            }
          })
          .catch(err => {
            this.showLoader = false;
          });
      },


      init() {
        this.pageLoaded = false;
        this.offset = 0;
        this.api.request.myRequests(10, this.offset)
          .then(async ({data}) => {
            let result = data;

            if (result.success) {
              await this.$store.state.categoriesPromise
              this.requests = result.data.requests;
              this.totalCount = result.data.totalCount;
              this.seemorediv = result.data.seemorediv;
              this.offset += 10;
              this.pageLoaded = true;
            } else {
              //this.$router.push("/");
            }
          })
          .catch(err => {
            this.$router.push("/");
          });
      },

      activateCreateSection() {
        /*
        window.hj('trigger', 'new_buyer_request');
        window.hj('tagRecording', ['new_buyer_request']);
        sessionStorage.setItem("hj_trig", 'new_buyer_request');
        */
        window.scrollTo(0,0);
        this.activateCreateRequest = true;
        this.Helper.trackEvents.pageView(null, 'createRequest', 'v3');
      },

      deactivateCreateSection() {
        window.scrollTo(0,0);
        this.activateCreateRequest = false;
        this.selectedCat = null;
        this.selectedSubCat = null;
        this.description = null;
        this.title = null;
        this.duration = null;
        this.budgetText = '';
        this.budget = null;
        this.duration = null;
        this.requestFiles = [];
        this.wordCount = null;
      },

    },
    computed: {
      computedMetaOptions: function (){
        if(this.selectedSubCat?.meta_options?.length > 0){
          if(this.selectedSubCat?.category_service_types?.length > 0 && this.selectedServiceType ){
            this.selectedSubCat.meta_options.forEach(mm =>{
              mm.dontShow = false
              if(mm.info.excluded_service_types?.length && mm.info.excluded_service_types.indexOf(this.selectedServiceType) > -1){
                mm.dontShow = true
                mm.selected = false;
              }
            })
          }
          this.reRender = false;
          this.reRender = true;
          return this.selectedSubCat.meta_options
        } else {
          return [];
        }
      },
      showMetaOptions(){
        return (this.step === 2 && this.computedMetaOptions && this.computedMetaOptions.length)
          && (this.selectedServiceType || (this.selectedSubCat.category_service_types.length === 0 ) )
      },

      showStep2TypeArea(){

        if(this.step === 2 && this.selectedSubCat && this.selectedSubCat.id &&
          (this.selectedSubCat.category_service_types && this.selectedSubCat.category_service_types.length > 0)
          ||
          (this.selectedSubCat.meta_options && this.selectedSubCat.meta_options.length > 0)
        )
        {
          return true;
        } else {
          return false
        }
      },
      showTooltip() {
        if (this.hideTooltip) {
          return false
        } else {
          return this.step > 2
        }
      },
      enableButton() {
        let ret = false
        if (this.step === 1) {
          if (this.selectedCat.id) {
            ret = true;
          }
        }
        if (this.step === 2) {
          if(this.selectedSubCat.id){
            if(this.showStep2TypeArea){
              let ret_type = true;
              let ret_meta = true;
              if (this.selectedSubCat.category_service_types.length > 0) {
                ret_type = !!this.selectedServiceType;
              }

              if(ret_meta && ret_type){
                ret = true;
              }
            } else {
              ret = true;
            }
          }

        }
        if(this.step === 3){
          if(this.selectedSubCat.id && this.selectedSubCat.id === 38 && this.wordCount){
            ret = true;
          }
          if(this.selectedSubCat.id && this.selectedSubCat.id === 61 && this.wordCount){
            ret = true;
          }
        }
        if(this.step === 4){
          if(this.duration && this.budget){
            ret = true;
          }
        }
        if(this.step === 5){
          if(this.title && this.description){
            ret = true;
          }
        }

        if(this.buttonLoading){
          ret = false;
        }

        return ret;
      },

      stepName: function () {
        if(this.step === 1){
          return 'Kategori Seçimi';
        }
        if(this.step === 2){
          return 'Alt Kategori Seçimi';
        }
        if(this.step === 3){
          return 'Alt Kategori Detayları';
        }
        if(this.step === 4){
          return 'Bütçe ve Süre';
        }
        if(this.step === 5){
          return 'Neredeyse Bitti!';
        }
      }
    },

    watch: {
      selectedServiceType(newVal, oldVal) {
        if(this.step === 2 && newVal && newVal !== oldVal){
          let isSelectedMetaOption = false;
          this.selectedSubCat.meta_options.forEach((o,oi) => {
            if(!isSelectedMetaOption){
              if(o.info?.excluded_service_types?.length && o.info?.excluded_service_types.indexOf(this.selectedServiceType) > -1){
                o.selected = false;
              } else {
                this.selectedMetaOption = o;
                o.selected = true;
                isSelectedMetaOption = true;
              }
            } else {
              o.selected = false;
            }

            o.options.forEach( (oo,ooi) => {
              oo.selected = false;
            })
          });
          this.reRender = false;
          this.reRender = true;
        } else {
          this.reRender = false;
          this.reRender = true;
        }
      },
      dummyMetaOption(newVal) {
        let selectedMetaOption = this.selectedMetaOption;
        if(newVal){
          if(selectedMetaOption?.info?.type === 'selectbox'){
            this.selectMetaOption(newVal)
          }
        }
      },
      requests(newVal, oldVal) {
        if(newVal.length && newVal.length !== oldVal.length){
          this.$nextTick(function () {
            // DOM updated

            const requests = JSON.parse(JSON.stringify(this.requests));

            let elems = document.getElementsByClassName("body");

            if(elems.length){
              for (let i = 0; i < elems.length; i++) {
                if(elems[i] && this.isOverflown(elems[i])){
                  if(elems[i]){
                    requests[i]['expandible'] = elems[i].scrollHeight;
                  }
                } else {
                  requests[i]['expandible'] = false;
                }
                this.requests = requests;
              }
            }
          });
        }
      }

    },


    created() {
      window.addEventListener('dragleave', this.dragLeaveEvent, false);
      window.addEventListener('dragenter', this.dragStartEvent, false);
      this.EventBus.$on('dragAndDropFile', droppedFile => {
        this.dragAndDropArea = false;
      });
      this.init();
    },

    beforeDestroy() {
      this.EventBus.$off('dragAndDropFile');
      window.removeEventListener('dragleave', this.dragLeaveEvent, false);
      window.removeEventListener('dragenter', this.dragStartEvent, false);
    }
  }
</script>

<style scoped lang="scss">

.request-meta_options{
  padding-top: 15px;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 10px;
  border-top: 1px solid #dadbdd;

  .meta-option-title{
    color: #95979d;
    margin-bottom: 5px;
  }

  .meta-option-values{
    color: #62646a;
    margin-bottom: 3px;

  }
}

.breadcrumb {
  font-size: 13px;
  text-align: left;
  color: #5e6b79;
  margin-top: 10px;
  margin-left: 30px;
}



  .u-plus-box {
    display: flex;
    margin-top: 30px;
    width: 860px;
    height: 540px;

    border-radius: 10px;
    box-shadow: 0 20px 20px 0 #e9ebf0;
    border: solid 1px #eff3f8;
    background-color: #ffffff;

    .left-b{
      margin-top: 30px;
      margin-left: 30px;
      width: 382px;
      text-align: center;

      .price{
        text-decoration: line-through;
        font-size: 42px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.5px;
        color: #8b95a1;
        span{
          font-size: 32px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: -0.5px;
          color: #8b95a1;
        }
      }
    }
    .right-b{
      margin-left: 30px;
      margin-top: 30px;

      .title{
        font-size: 46px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #fd4056;
        span{
          font-weight: bold;
        }
      }

      .sub-title{
        font-size: 16px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: -0.3px;
        color: #5e6b79;
        span{
          font-weight: bold;
          color: #2d3640;
        }
      }

      .li-container{
        margin-top: 25px;
        .li-row{
          display: flex;
          min-height: 20px;
          margin-top: 30px;
          align-items: center;
          .check-icon-container{
            .check-icon /deep/ {
              width: 20px;
              height: 20px;
              path{
                fill: #00a575;
              }
            }
          }

          .li{
            margin-left: 10px;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.71;
            letter-spacing: normal;
            color: #5e6b79;
            span{
              font-weight: bold;
              color: #2d3640;
            }
          }
        }
      }
    }
  }
  .plus-container{
    width: 410px;
    height: 227px;
    border-radius: 10px;
    border: solid 1px #bfc8d2;
    background-color: #ffffff;
    display: flex;
    cursor: pointer;

    .text{
      margin-top: 5px;
      font-size: 14px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: -0.26px;
      color: #5e6b79;
      span{
        font-weight: 600;
      }
    }

    &:hover{
      border: solid 1px #00a575;
    }
    &.active{
      border: solid 1px #00a575;
      background-color: rgba(103, 204, 100, 0.1);
      .text{
        color: #2D3640;
      }
    }
    img{
      width: 110px;
      margin-top: 50px;
      margin-left: 25px;
    }
    .title{
      font-size: 18px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      color: #2d3640;
    }

  }

  .a-inner{
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    transform: rotate(45deg);
    background-color:#ffbf00;


    width: 25px;
    height: 40px;
    top: -35px;
    position:relative;
    -moz-border-radius: 3px;
    border-radius: 3px;

  }

  .a-outer {
    z-index: 999999999;
    position: absolute;
    width: 20px;
    height: 20px;
    margin-left: 82px;

    overflow: hidden;

  }

  .arrow_box {

    border-radius: 2px;
    background: #ffbf00;
    font-size: 13px;
    font-weight: 300;
    line-height: 1.46;
    width: 178px;
    height: 92px;
    color: #2d3640;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .read-more{
    margin-left: 30px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.38;
    color: #f75466;
    display: flex;
    align-items: center;
    height: 20px;
    padding-bottom: 10px;
    cursor: pointer;
    &:hover{
      text-decoration: underline;
    }

    .read-more-icon /deep/ {

      width: 14px;
      height: 14px;
      path {
        fill: #fd4056;
      }
    }

  }

  .request-list{
    margin-left: 57px;
    .page-title{
      font-size: 34px;
      font-weight: 400;
      color: #2d3640;
      span{
        font-weight: 600;
      }
    }

    .page-sub-title{
      font-size: 18px;
      margin-top: 10px;
      line-height: 1.44;
      letter-spacing: normal;
      color: #6a7685;
      max-width: 548px;
    }


    .request-box{
      margin-top: 50px;
      width: 843px;
      object-fit: contain;
      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.15);
      background-color: #ffffff;


      .extra-info-label
        {

        height: 21px;
        padding: 2px 10px 3px;
        border-radius: 10px;
        background-color: #26ca9a;
        font-size: 12px;
        letter-spacing: -0.22px;
        font-weight: normal;

        text-align: center;
        margin-left: 10px;
        color: #ffffff;
      }
      .head{
        width: 843px;
        height: 80px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        background-color: #8b95a1;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .head-active{
        width: 843px;
        height: 80px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        background-color: #2d3640;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .avatar{
        height: 44px;
        width: 44px;
        border: 1px solid #fff;
        border-radius: 50%;
      }

      .username{
        font-size: 18px;
        font-weight: normal;
        color: #ffffff;
      }

      .usertitle{
        margin-top: 5px;
        font-size: 14px;
        font-weight: normal;
        color: #ffffff;
      }

      .head-label{
        padding-left: 20px;
        padding-right: 20px;
        height: 28px;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: normal;
        text-align: center;
        color: #ffffff;
        margin-left: 20px;
        padding-bottom: 2px;
        a{
          color: #ffffff;
        }
      }

      .green{
        background-color: #6dce6a;
      }




      .red{
        background-color: #fd4056;
      }

      .yellow{
        background-color: #f29933;
      }


      .icon-container{
        width: 30px;
        height: 30px;
        border-radius: 5px;
        background-color: #ffffff;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 20px;


        .head-eye-icon /deep/ {
          width: 14px;
          height: 14px;
          path {
            fill: #2d3640;
          }
        }

        .head-delete-icon /deep/ {
          width: 11px;
          height: 14px;
          path {
            fill: #2d3640;
          }
        }

        .head-edit-icon /deep/ {
          width: 14px;
          height: 14px;
          path {
            fill: #2d3640;
          }
        }

        &:hover{
          .head-eye-icon /deep/ {
            width: 14px;
            height: 14px;
            path {
              fill: #00a575;
            }
          }

          .head-delete-icon /deep/ {
            width: 11px;
            height: 14px;
            path {
              fill: #00a575;
            }
          }

          .head-edit-icon /deep/ {
            width: 14px;
            height: 14px;
            path {
              fill: #00a575;
            }
          }
        }


      }


      .body{
        height: 192px;
        overflow-y:hidden;

        .body-title{
          padding: 25px 30px 15px;
          font-size: 18px;
          font-weight: bold;
          color: #2d3640;
        }
        .body-text{
          padding-left: 30px;
          padding-right: 30px;
          padding-bottom: 10px;
          font-size: 16px;
          line-height: 1.63;
          color: #5e6b79;
          word-break: break-word;
        }
      }

      .foot{
        width: 843px;
        height: 70px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        box-shadow: 0 -1px 0 0 #eaedf2;
        background-color: #f9fafb;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .foot-date{
          margin-left: 30px;
          height: 30px;
          border-radius: 5px;
          background-color: #f4f5f7;
          padding-right: 20px;
          padding-left: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          font-weight: 600;
          text-align: center;
          color: #8b95a1;
        }

        .foot-right{
          display: flex;
          margin-right: 30px;
          .item{
            margin-left: 30px;
            display: flex;
            align-items: center;

            .foot-icon /deep/ {

              width: 24px;
              height: 24px;
              path {
                fill: #8b95a1;
              }
            }

            .foot-title{
              margin-left: 10px;
              font-size: 16px;
              font-weight: normal;
              color: #2d3640;
            }

            .foot-value{
              margin-left: 5px;
              font-size: 16px;
              font-weight: 600;
              color: #2d3640;
            }

            .foot-show{
              cursor: pointer;
              margin-left: 5px;
              font-size: 16px;
              font-weight: 600;
              color: #00a575;
              &:hover{
                text-decoration: underline;
              }
            }

          }
        }
      }
    }
  }


  .icon-area{

    cursor: pointer;

    .delete-uploaded-file-img /deep/ {
      width: 14px;
      height: 14px;

      path {
        fill: #8b95a1;
      }
    }


    .delete-icon /deep/ {
      cursor: pointer;
      width: 11px;
      height: 14px;
      path {
        fill: #8b95a1;
      }

    }
    &:hover{

      .delete-uploaded-file-img /deep/ {
        width: 14px;
        height: 14px;

        path {
          fill: #2d3640;
        }
      }


      .delete-icon /deep/ {
        cursor: pointer;
        width: 11px;
        height: 14px;
        path {
          fill: #2d3640;
        }

      }
    }
  }


  .check-icon /deep/ {
    width: 20px;
    height: 15px;
    path {
      fill: #00a575;
    }
  }



  /*upload and drag/drop*/
  #request-drag-drop {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    -webkit-transition: all 1s;
    transition: all 0.5s;
    font-size: 20px;
    color: rgb(230, 50, 98);
    font-weight: 500;


    margin-top: 30px;
    text-align: center;
    width: 393px;
    height: 380px;
    border-radius: 10px;
    border: dashed 2px #bfc8d2;
    background-color: rgba(191, 200, 210, 0.1);

  }

  .upload-plus-icon /deep/ {
    width: 18px;
    height: 18px;
    path {
      fill: #fff;
      &:hover{
        opacity: 0.8;
      }
    }


  }


  .upload-area-container{
    margin-top: 33px;
    display: flex;
    justify-content: space-between;
  }

 .upload-area-files{
   width: 393px;
   margin-left: 57px;

    .upload-files-title{
      font-size: 18px;
      font-weight: 600;
      color: #5e6b79;
    }
    .upload-file-count{
      font-size: 16px;
      font-weight: 500;
      color: #5e6b79;
    }

  }
  .upload-area{

    text-align: center;
    width: 393px;
    height: 380px;
    border-radius: 10px;
    border: dashed 2px #bfc8d2;
    background-color: rgba(191, 200, 210, 0.1);

    .upload-title{
      margin-top: 50px;
      font-size: 26px;
      font-weight: 600;
      line-height: 1.08;
      color: #5e6b79;
    }
    .upload-sub-title{
      margin-top: 10px;
      font-size: 12px;
      font-weight: 300;
      letter-spacing: -0.23px;
      color: #5e6b79;
    }
    .upload-or{
      margin-top: 40px;
      font-size: 14px;
      color: #8b95a1;
    }
    .upload-foot-title{
      margin-top: 64px;
      font-size: 14px;
      font-weight: 600;
      color: #2d3640;
    }
    .upload-foot-sub-title{

      max-width: 85%;
      margin: 10px auto 0;
      font-size: 12px;
      font-weight: 300;
      color: #8b95a1;
    }
  }

  .step-2-container{
    margin-top: 60px;
    .header-img{
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      width: 843px;
      height: 103px;
      background-repeat: no-repeat;
      background-size: 843px;
      border-radius:10px;
    }

    .step-2-text-container{
      margin-left: 108px;
    }
    .step-2-title{
      font-size: 18px;
      font-weight: 600;

      margin-top: 5px;
    }
    .step-2-sub-title{
      font-size: 16px;
      line-height: 1.25;

      span{
        font-weight: bold;
      }
    }




  }

  .sub-cat-box-container{
    display: flex;
    flex-wrap: wrap;
    width: 843px;
    justify-content: space-between;
    .sub-cat-box{
      cursor: pointer;
      margin-top: 30px;
      width: 393px;
      height: 79px;
      border-radius: 5px;
      border: solid 1px #bfc8d2;
      background-color: #ffffff;
      font-size: 18px;
      color: #8b95a1;
      display: flex;
      align-items: center;
      span{
        padding-left: 25px;
      }
      &:hover{
        border: solid 1px #00a575;
        background-color: #ffffff;
        color: #2d3640;
      }
    }

    .sub-cat-box-active{
      margin-top: 30px;
      width: 393px;
      height: 79px;
      border-radius: 5px;
      border: solid 1px #00a575;
      background-color: rgba(103, 204, 100, 0.1);
      font-size: 18px;
      color: #2d3640;
      display: flex;
      align-items: center;
      span{
        padding-left: 25px;
      }

    }
  }
  .bottom-fixed-div {
    position:fixed; bottom:0; width: 100%; background: linear-gradient(to right, #0e0e0f, #2d3740); height: 100px; z-index:41;
    left: 0;

    .continue-button{
      position: absolute;
      right: 0;
      background: #8b95a1; height: 100px; width: 200px;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: -0.38px;
      text-align: center;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .continue-button-active{
      position: absolute;
      right: 0;
      cursor: pointer;
      -webkit-transition: all 0.18s ease-out;
      -moz-transition: all 0.18s ease-out;
      -ms-transition: all 0.18s ease-out;
      -o-transition: all 0.18s ease-out;
      transition: all 0.18s ease-out;

      height: 100px; width: 200px;
      background-color: #00a575;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: -0.38px;
      text-align: center;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover{
        height: 120px; width: 240px;

      }
    }

    .bottom-cat-img-container{
      cursor: pointer;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      width: 46px;
      height: 46px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      .bottom-cat-img{
        width: 22px;
        height: 22px;
      }
    }

    .bottom-cat-title{
      cursor: pointer;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.13;
      color: #ffffff;
      margin-left: 10px;

    }

    .bottom-centered{
      margin: 0 auto;
      width: 1440px;
      max-width: 100%;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: space-between;


    }
    .step-title{
      font-size: 18px;
      font-weight: 600;
      line-height: 1.44;
      color: #ffffff;
      margin-bottom: 5px;
    }

    .step-sub-title{
      font-size: 24px;
      font-weight: 300;
      letter-spacing: -0.46px;
      color: #ffffff;
    }
  }




  .create-area{
    margin-left: 57px;
    margin-top: 30px;

    .title{
      font-size: 34px;
      color: #2d3640;
      margin-bottom: 14px;
    }
    .sub-title{
      font-size: 18px;
      line-height: 1.44;
      color: #6a7685;
      span{
        font-weight: bold;
        color: #2d3640;
      }
    }

    .main-cats-container{
      margin-top: 60px;
      display: flex;

      flex-wrap: wrap;
      .main-cats{
        margin-bottom: 30px;
      }

      .main-cats-bg{
        cursor: pointer;
        width: 215px; height: 190px;
        background-repeat: no-repeat;
        background-size: 190px 190px;

        .cat-title{
          padding-top: 106px;
          padding-left: 20px;
          padding-bottom: 5px;
          font-size: 16px;
          font-weight: 600;
          line-height: 1.18;

        }
        .cat-sub-title{
          padding-left: 20px;
          opacity: 0.4;
          font-size: 14px;
          font-weight: normal;
          line-height: 1.43;

          span{
            font-weight: bold;
          }
        }
      }

      img{
        width: 190px;
        height: 190px;
      }
    }
  }

  .no-area{

    margin: 50px auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    .img{
      width: 335px;
      height: 203px;
    }

    .title{
      margin-top: 50px;
      margin-bottom: 16px;
      font-size: 34px;
      font-weight: normal;
      text-align: center;
      color: #2d3640;
      span{
        font-weight: bold;
      }
    }
    .text{
      max-width: 543px;
      font-size: 18px;
      line-height: 1.44;
      text-align: center;
      color: #5e6b79;
    }
  }

  .sub-cat-type-kind-container{
    padding-top: 20px;
    .title{
      font-size: 26px;
      font-weight: 500;
      color: #2d3640;
      margin-top: 40px;
    }

    .help-text{
      margin-left: 5px;
      font-size: 16px;
      font-weight: normal;
      line-height: 1.5;
      color: #5e6b79;
      span{
        font-weight: bold;

      }
    }

  }

  .meta-options-box{
    display:flex;border-radius: 5px;
    border: solid 1px #bfc8d2;
    margin-top:30px;
    background-color: #fff; min-height: 250px; width: 100%;

    .meta-options-right{
      flex:2;
      background-color: #f3f5f7;
      border-top-left-radius: 4px;
      border-right:solid 1px #bfc8d2;
    }

    .meta-options-right-row-selected{
      background-color: white; border-top-left-radius: 4px;
      width: 101%; height: 60px; display: flex;
      align-items: center; border-bottom:solid 1px #bfc8d2;
    }

    .meta-options-right-row{
      border-top-left-radius: 4px; width: 100%; height: 60px;
      display: flex; align-items: center; border-right:solid 1px #bfc8d2;
      cursor: pointer;
      &:hover{
        opacity: 0.8;
      }
    }

    .meta-options-right-row-text{
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-left: 25px;
      padding-right: 25px;
      font-size: 18px;font-weight: 500;

    }

    .check-icon-container{
      .check-icon /deep/ {
        width: 18px;
        height: 18px;
        path {
          fill: #00a575;
        }
      }
    }

    .meta-options-left{
      flex:5;
      .option-left-title{
        font-weight: 600;
        font-size: 15px;
        padding-top: 20px;
        padding-left: 30px;
      }

      .meta-options-left-form{
        margin-left: 30px;
        margin-top: 30px;
      }
    }

  }

  .filter-radio-item{
    display: flex; align-items: center; cursor: pointer;
    margin-bottom: 20px;

    .filter-radio-empty{
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: solid 1px #8b95a1;
    }

    .filter-radio-label{
      font-size: 16px;  color: #5e6b79; margin-left: 8px; line-height: 1.4;

    }

    .filter-radio-label-active{
      font-size: 16px; color: #5e6b79; font-weight: 400; margin-left: 8px; line-height: 1.4
    }

    .filter-radio-full{
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border: solid 3px #00a575;
    }


  }


  .filter-checkbox-item{
    cursor: pointer;
    display: flex;
    width: 50%;
    margin-bottom: 24px;


    .filter-checkbox-disabled{
      width: 16px;
      height: 16px;
      border-radius: 4px;
      border: solid 1px #8b95a1;
      opacity: 0.4;
    }

    .filter-checkbox-label-disabled{
      padding-left: 10px;
      font-size: 15px;
      opacity: 0.55;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: #2D3640;

    }

    .filter-checkbox-empty{
      width: 16px;
      height: 16px;
      border-radius: 4px;
      border: solid 1px #8b95a1;
    }

    .filter-checkbox-full{
      width: 12px;
      height: 12px;
      border-radius: 5px;
      border: solid 3px #00a575;

    }

    .filter-checkbox-label{
      padding-left: 10px;
      font-size: 15px;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: #5e6b79;

    }

    .filter-checkbox-label-active{
      padding-left: 10px;
      font-size: 15px;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: #5e6b79;

    }

  }

</style>
